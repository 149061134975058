/* Common Styles */
.Q-advace-card, .Q-advace-card1 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
  padding: 20px;
  overflow: hidden;
}

.Q-advace-card {
  width: 100%;
  height: 150px;
  margin-bottom: 20px; /* Add margin bottom for spacing between cards */
}

.Q-advace-card1 {
  width: 100%;
  height: 150px;
  margin-bottom: 20px; /* Add margin bottom for spacing between cards */
}

.Q-advace-card-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .Q-advace-card, .Q-advace-card1 {
    width: 100%;
    height: auto; /* Let height adjust automatically */
  }

  .Q-advace-card-content {
    padding: 20px;
  }
}
