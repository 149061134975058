/* card.css */

.lucent-Card {
  display: flex; /* Use flexbox to center the content vertically and horizontally */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 168px;
  height: 120px;
  margin: 5px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
}

.lucent-Card .card-body {
  width: 100%;
}

.lucent-Card p {
  text-align: center;
}

@media (max-width: 768px) {
  .lucent-Card {
    width: 80%;
    margin: 5px;
  }
}

.card-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Adjust column widths */
  gap: 20px; /* Adjust spacing between cards */
}

.card-content {
  padding: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.card-title {
  margin-bottom: 10px;
}

.card-title h2 {
  font-size: 18px; /* Adjust title font size */
  font-weight: bold;
  margin: 0;
}

.card-subtitle {
  margin-bottom: 15px;
}

.card-subtitle p {
  font-size: 14px; /* Adjust subtitle font size */
  color: #333; /* Set subtitle color */
  margin: 0;
}

.card-body {
  display: grid;
  /* grid-template-columns: repeat(1);  */
  grid-gap: 10px; /* Add spacing between columns */
}

.key-card-body {
  display: grid;
  margin-top: 25px;
  text-align: center;
  grid-gap: 10px;
  font-family: Lato;
  font-weight: bolder;
}
/* Customize card body content styling as needed */
.key-Card {
  display: inline-block;
  width: 130%;

  height: 200px;
}

.card-img {
  width: 100%;
}

.vital-Card {
  display: inline-block;
  padding: 10px;
  width: 400px;
  height: 100px;
  margin: 5px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8); /* Increased shadow effect */
  /* transition: all 0.1s ease-in-out; */
  opacity: 0.9; /* Increased opacity */
  transition: opacity 0.2s ease-in-out;

  /* Responsiveness using media queries */
  @media (max-width: 768px) {
    width: 80%;
    margin: 5px;
  }
}

clickable-icon {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.clickable-icon:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%; /* change this value to control the aspect ratio of the icon */
}

.clickable-icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.clickable-icon:hover img {
  transform: scale(1.1);
}

/* .advace-card {
  display: flex; 
  justify-content: center; 

  width: 18rem;
  height: 220px;

  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
}
.advace-card-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 90%;
}
.Advance-lucent-chart {
  padding: 60px;
} */
/* .advace-card-3 {
  display: flex; 
  justify-content: center; 
 
  width: 88%;
  height: 100%;
 
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
} */

/* .advace-card-31 {
  display: flex;
  justify-content: center;

  width: 288px;
  height: 165px;

  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
} */
.card-body p {
  text-align: justify;
}

.lucent-Card {
  display: flex;
  justify-content: center;

  width: 180px;
  height: 150px;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
}
.lucent-Card p {
  padding: 20px;
}

.lucent-Card-1 {
  display: flex;
  justify-content: center;

  width: 260px;
  height: 120px;

  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
}

.card-body-1 {
  padding: 20px;
  /* display: flex; */
  text-align: center;
}




















/* Common Styles */
.p-advace-card, .p-advace-card1 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
  padding: 20px;
  overflow: hidden;
}

.p-advace-card {
  width: 100%;
  height: 90%;
  margin-bottom: 20px; /* Add margin bottom for spacing between cards */
}

.p-advace-card1 {
  width: 85%;
  height: 90%;
  margin-bottom: 20px; /* Add margin bottom for spacing between cards */
}

.p-advace-card-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .p-advace-card, .p-advace-card1 {
    width: 100%;
    height: auto; /* Let height adjust automatically */
  }

  .p-advace-card-content {
    padding: 20px;
  }
}
