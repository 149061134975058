.card {
  position: absolute;
}

.card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Border */
}

.card-content:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.looking-for {
  text-align: center;
}

.looking-for img {
  width: 40%;
}
.Qad-advace-card-3 {
  display: flex; /* Use flexbox to center the content vertically and horizontally */
  justify-content: center; /* Center horizontally */
  /* Center vertically */
  width: 610px;
  height: 170px;

  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
}




/* Add this to your CSS file */

@media (max-width: 768px) {
  .responsive-col {
    width: 100% !important; /* Ensure full width on small screens */
  }

  .responsive-image {
    width: 100%; /* Ensure image takes full width */
    height: auto; /* Maintain aspect ratio */
  }

  .responsive-text {
    font-size: 16px; /* Adjust text size for better readability */
  }
}
