/* Container holding the cards */
.card-container {
  display: flex;
  flex-wrap: wrap; /* Wraps cards to the next line if necessary */
  gap: 16px; /* Adds space between cards */
}

.Q-card {
  flex: 1 1 30%; /* Allow the card to grow, shrink, and set a base width */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure space between content */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.3); /* Stronger shadow for normal state */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5%;
  background-color: white;
  overflow: hidden; /* Ensure content does not overflow */
  height: 100%; /* Ensure card stretches to fill the container */
}

.Q-card:hover {
  border-color: #007bff; /* Change this color as needed */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Even stronger shadow on hover */
  transform: translateY(-5px); /* Slight lift on hover */
}

.Q-card-content {
  margin-top: 12px;
}

.card-title {
  margin-bottom: 5%;
  text-align: center;
}

/* Media queries for responsive adjustments */
@media (max-width: 768px) {
  .Q-card {
    padding: 8px;
  }
  .Q-card-content {
    margin-top: 10px;
  }
  .card-title h2 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }
  .card-body p {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}
