.card {
  position: absolute;
}

.card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Border */
}

.card-content:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.looking-for {
  text-align: center;
}

.looking-for img {
  width: 40%;
}

.Qad-advace-card-32 {
  width: 50vw;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
  padding: 20px;
  overflow: hidden;
}

.advace-card {
  width: 22vw;
  height: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
  padding: 20px;
  overflow: hidden;
}

.advace-card-23 {
  width: 22vw;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
  padding: 20px;
  overflow: hidden;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
  .advace-card,
  .advace-card-23,
  .Qad-advace-card-32 {
    width: 80%; /* Reduce width for smaller screens */
    margin: 20px auto; /* Center the cards and add some spacing between them */
    height: auto; /* Allow height to adjust based on content */
  }
}

/* Further adjustments for even smaller screens */
@media only screen and (max-width: 480px) {
  .advace-card,
  .advace-card-23,
  .Qad-advace-card-32 {
    width: 90%; /* Further reduce width for very small screens */
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Gadditional-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); */
  grid-template-columns: repeat(4, 1fr); /* Ensure four columns */
  gap: 10px;
  justify-content: center;
}

.Gadditional-images img {
  /* width: 150px;
  height: 150px; */
  width: 90%;
  height: 80%;
  margin: 5px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  border: 1px solid #ddd;

  /* width: 100%;
  height: 100%;
  border: 1px solid #ddd; 
  border-radius: 10px;
  object-fit: cover; */
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain; /* Ensure image is contained within modal without distortion */
  width: auto; /* Automatically adjust width based on image aspect ratio */
  height: auto; /* Automatically adjust height based on image aspect ratio */
}
